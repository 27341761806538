import React, { useState, useEffect } from "react";
import AppContainer from "../AppContainer/AppContainer";
import propTypes from "prop-types";
import Loading from "../Loading/Loading";
import {
  login as authenticate,
  jwtLogin,
  acquireLegacyJwt,
  updateMsalJWT,
  checkedForLegacy,
} from "_shared/auth";
import {
  getTokenRedirect as acquireMsalToken,
  initializeMsal,
  signOut,
} from "_shared/authRedirect";
import { getRedirectRoute } from "../AuthorizedRoute/AuthorizedRoute";
import { useForm } from "react-hook-form";
import { isEmpty } from "ramda";
import { Redirect } from "react-router-dom";
import {
  restoreDefault,
  HOSTNAME_WHITE_LIST,
  hostname as CurrentDomain,
} from "../../utils/constants";
import "./LogInBox.scss";
import { useTranslation } from "react-i18next";
import CircleX from "_shared/images/icons/circle-x.svg";

const SIGNOUTTIMER = 5 * 1000;
const AZURERETRYENVCOUNT = "applied-azure-retry-count";

const azureAdFailed = () => {
  console.log("azureFailed");
  const retryCount = global.sessionStorage.getItem(AZURERETRYENVCOUNT);
  const count = retryCount !== null ? parseInt(retryCount, 10) : 0;
  global.sessionStorage.setItem(AZURERETRYENVCOUNT, count + 1);
};

const resetAzure = () => {
  console.log(
    "azure ad successful, resetting count from",
    global.sessionStorage.getItem(AZURERETRYENVCOUNT),
    "to 0"
  );
  global.sessionStorage.setItem(AZURERETRYENVCOUNT, 0);
};

const LogInBox = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [azureFailed, setAzureFailed] = useState(false);
  const [awatingSignout, setAwatingSignout] = useState(false);
  const [counter, setCounter] = useState(SIGNOUTTIMER);
  const { t } = useTranslation();

  const onSubmit = async (auth) => {
    try {
      setError("");
      setLoading(true);
      const data = await jwtLogin(auth)();

      if (data) {
        setIsLoggedIn(true);
        setLoading(false);
      } else {
        setError("Wrong username/password or server issues");
        setIsLoggedIn(false);
        setLoading(false);
      }
    } catch (e) {
      setError("Wrong username/password or server issues");
      setIsLoggedIn(false);
    }
  };

  const login = async () => {
    setLoading(true);
    let initResponse = await initializeMsal();
    const loginProcess = async () => {
      try {
        setError("");
        const msalRespObj = await acquireMsalToken();
        updateMsalJWT(msalRespObj);
        const data = await acquireLegacyJwt(msalRespObj);
        if (data.CostCenter === "") {
          setAwatingSignout(true);
          setTimeout(() => {
            signOut(msalRespObj.account.username);
          }, SIGNOUTTIMER);
        } else if (!isEmpty(data)) {
          if (data.username !== "zzKiosk") {
            setIsLoggedIn(true);
            resetAzure();
          }
          if (data.username === "zzKiosk") {
            setIsLoggedIn(false);
            setError("");
          }
        } else {
          setIsLoggedIn(false);
          setAzureFailed(true);
        }
      } catch (e) {
        console.warn({ e });
        if (
          (parseInt(global.sessionStorage.getItem(AZURERETRYENVCOUNT), 10) <=
            2 ||
            global.sessionStorage.getItem(AZURERETRYENVCOUNT) === null) &&
          checkedForLegacy === true
        ) {
          setError(`${e}`);
        }
        setIsLoggedIn(false);
        azureAdFailed();
      } finally {
        setLoading(false);
      }
    };

    if (
      parseInt(global.sessionStorage.getItem(AZURERETRYENVCOUNT), 10) <= 2 ||
      global.sessionStorage.getItem(AZURERETRYENVCOUNT) === null
    ) {
      loginProcess();
    } else {
      await legacyLogin();
    }
  };

  const legacyLogin = async () => {
    try {
      setLoading(true);
      setAzureFailed(true);
      const data = await authenticate();
      resetAzure();
      console.log({ data });
      if (!isEmpty(data) && data.username !== "zzKiosk") {
        setIsLoggedIn(true);
      } else if (!isEmpty(data) && data.username === "zzKiosk") {
        setIsLoggedIn(false);
        setError("");
      } else {
        setIsLoggedIn(false);
        setError(t("Wrong username/password or server issues"));
      }
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.jwt) {
      legacyLogin();
    } else {
      login();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter - 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, [awatingSignout, counter]);

  const { handleSubmit, register, errors } = useForm({
    nativeValidation: true,
  });

  const redirectRoute = getRedirectRoute();

  const redirectTo = redirectRoute ? redirectRoute : "/";

  useEffect(() => {
    return () => {
      global.localStorage.removeItem("mappedin-route");
      console.log("this is cleanup in login box for routes", global.localStorage.getItem("applied-saved-route"));
    }
  }, []);


  return isLoggedIn ? (
    <>
    <Redirect to={redirectTo} />
    </>
  ) : (
    <AppContainer
      withBackground={false}
      render={({ width, height }) => {
        return (
          <div styleName="loginContainer" style={{ width, height }}>
              {!awatingSignout ? (
                azureFailed ? (
                  <div styleName="greetings">
                    <p>{t("Please sign in!")}</p>
                  </div>
              ) : (
                !error && (
                  <div className="w-[281px] h-[164px] bg-white rounded-lg text-center">
                    <div className="pt-8 pb-5">
                      <Loading />
                    </div>
                    <p className="text-[22px] text-[#373D43] font-semibold text-center m-0" style={{ textShadow: 'none' }}>
                      {t("Logging you in...")}
                    </p>
                  </div>
                )
              )
            ) : (
              <div styleName="greetings">
                <p>
                  {`Logging you out in ${counter / 1000} due to the use of an admin(el) / or invalid account, please use standard accounts`}
                </p>
              </div>
            )}
            <div styleName="search">
              {azureFailed && (
                <>
                  <form
                    styleName="loginContainer"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <input
                      type="text"
                      name="username"
                      data-cy="username"
                      className="form-control"
                      styleName="search-bar"
                      required
                      placeholder={t("Username")}
                      ref={register({ required: true, maxLength: 80 })}
                    />
                    <input
                      ref={register({ required: true, maxLength: 80 })}
                      type="password"
                      data-cy="password"
                      name="password"
                      className="form-control"
                      styleName="search-bar"
                      placeholder={t("Password")}
                    />
                    <input
                      type="submit"
                      data-cy="submit"
                      styleName="loginButton"
                      value="Login!"
                    />
                  </form>
                </>
              )}
            </div>
            {error && (
            <>
              <div styleName="authContainer" className="w-[527px] h-[238px] bg-white rounded-lg flex justify-center items-center">
                <div className="w-[487px] h-[178px] flex justify-center items-center flex-col">
                  <img 
                  className="pb-4 h-[50px] w-[50px]"
                  src={CircleX}
                  />
                  <p className="text-[22px] font-semibold text-[#373D43] m-0">Authentication Failed</p>
                  <div className="pt-4 w-[415px] text-justify" style={{textAlignLast: "center", }}>
                    <p className="text-[14px] leading-[22px] text-[#373D43] m-0">Please check your network connection, 
                    refresh and try again. </p>
                    <p className="text-[14px] leading-[22px] text-[#373D43] m-0">
                    If the issue persists, try again later or 
                    contact our ServiceDesk team for assistance.
                    </p>
                    
                  </div>
                </div>
              </div>
            </>
            )}
          </div>
        );
      }}
    />
  );
};

LogInBox.propTypes = {
  location: propTypes.shape({ state: propTypes.object.isRequired }),
};

export default LogInBox;

import React, { useState, useEffect } from "react";
import "./BuildingInfo.scss";
// import { Link } from "react-router-dom";
import getOptimizedSrc from "_shared/useOptimizedImage";
import { useTranslation } from "react-i18next";
import { splitAt } from "ramda";
import RSMcampusMap from "_shared/pdf/campus-maps/RSM Campus Map.pdf";
import Alert from "../Alert/Alert";

const getImagesNames = () => {
  const r = require.context("_shared/images/campus-maps/", true);
  const importAll = (r) =>
    r.keys().map((file) => {
      return file.replace("./", "");
    });
  return importAll(r);
};

const getFileNames = () => {
  const r = require.context("_shared/pdf/campus-maps/", true);
  const importAll = (r) =>
    r.keys().map((file) => {
      return file.replace("./", "");
    });
  return importAll(r);
};

async function getImage(templateName) {
  try {
    let template = await import("_shared/images/campus-maps/" + templateName);
    return template.default;
  } catch (err) {
    return new Error(err);
  }
}

async function getFile(templateName) {
  try {
    let template = await import("_shared/pdf/campus-maps/" + templateName);
    return template.default;
  } catch (err) {
    return new Error(err);
  }
}

const images = getImagesNames();
const files = getFileNames();

const Address = ({ address }) => {
  return (
    <div className="mb-1 mt-6 text-xs text-[#5c666f] min-[400px]:text-sm">
      <p className="m-0">{address.streetAddress}</p>
      <p className="m-0">{address.citystatezip}</p>
      <p className="m-0">{address.country}</p>
    </div>
  );
};

const BuildingLinks = ({ pdfFile }) => {
  return pdfFile.map((file, index) => {
    return (
      <a
        href={file.file}
        download={file.label}
        styleName="campus-map__directions__link"
        key={index}
      >
        {file.label}
      </a>
    );
  });
};

const Links = ({ address, pdfFile, history, city }) => {
  const { t } = useTranslation();
  const openInNewTab = (file) => {
    const newWindow = window.open(
      file,
      "_blank",
      "noopener,noreferrer" //to avoid window hijacking
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div styleName="buildingLinks">
      <a
        href={`https://www.google.com/maps?daddr=${address.streetAddress} ${address.citystatezip} ${address.country}`}
        target="_blank"
        rel="noopener noreferrer"
        data-cy="link-driving"
        autoFocus
      >
        {t("Google Driving Directions")}
      </a>
      {pdfFile.length > 0 &&
      (address.addressDescription === null ||
        address.addressDescription.includes("Production")) ? (
        <BuildingLinks pdfFile={pdfFile} />
      ) : null}
      {address.citystatezip.includes("Rancho Santa Margarita") && (
        <a
          onClick={() => {
            openInNewTab(RSMcampusMap);
          }}
          styleName="campus-map__directions__link"
        >
          RSM Campus Map
        </a>
      )}
      {/* <Link
        to={{
          pathname: `/campusmap/${city}/MappedIn`,
        }}
        styleName="campus-map__directions__link"
      >
        MappedIn
      </Link> */}
    </div>
  );
};

const BuildingDetails = ({ address, pdfFile, history, city }) => {
  return (
    <div>
      <Address address={address} />
      <Links
        history={history}
        address={address}
        pdfFile={pdfFile}
        city={city}
      />
    </div>
  );
};

const BuildingCard = ({ building, history, city, setBuildingName,}) => {
  const buildingName = building.name;
  const [imgData, setImage] = useState({});
  const [pdfFile, setPdfFile] = useState({});
  const buildingInformations = files.filter(
    (el) => el.indexOf(buildingName) !== -1
  );
  useEffect(() => {
    const fetchImage = async () => {
      let i = 0;
      images.forEach((el, j) => {
        if (el.indexOf(buildingName) !== -1) {
          i = j;
        }
      });
      const img = await getImage(images[i]);
      setImage(getOptimizedSrc(img, 500).src);
    };

    const revisedBuildingInfo = buildingInformations.map((el) => {
      // File name for building pdf is `A100 A100 Building Map.pdf`
      const searchTerm = " ";
      const indexOfFirst = el.indexOf(searchTerm);
      const newName = splitAt(indexOfFirst, el);
      return newName[newName.length - 1].trimStart().split(".pdf");
    });



    const getFileNames = buildingInformations.map((el) => {
      return getFile(el);
    });
    const fetchFile = async () => {
      try {
        const fileNames = await Promise.all(getFileNames);
        const fileNamesWithLabel = fileNames.map((file, index) => {
          return {
            file,
            label: revisedBuildingInfo[index],
          };
        });
        setPdfFile(fileNamesWithLabel);
      } catch (e) {
        return new Error(e);
      }
    };
    fetchFile();
    fetchImage();
  }, []);

  return (
    <div className="mb-2 box-border block cursor-pointer h-auto w-full whitespace-normal border-t-0 border-l-0 border-r-0 border-b border-solid border-[#dadada] bg-white pt-[20px] pr-[15px] pb-[5px] pl-[13px] md:w-[230px] min-[1200px]:rounded-[10px] min-[1200px]:border min-[1200px]:border-[#fafbfb] min-[1200px]:shadow-[0_0_20px_0_rgba(241,241,242,0.7)] ">
      <div className="flex h-full">
        <img
          alt={building.name}
          src={imgData}
          className="ml-1 h-[100px] w-[100px] rounded"
          onClick={() => {
            setBuildingName(building.name)
          }}
        />
        <div className="ml-3 flex w-full cursor-pointer items-center justify-center border-none bg-transparent p-0 font-default text-[21px] text-[#0095a0]"
          onClick={() => {
            setBuildingName(building.name)
          }}
        >
          {building.name}
        </div>
      </div>
      <div className="relative top-[-8px] inline-block pl-[3%] text-[11px]">
        {building.addresses.map((address, index) => {
          return (
            <BuildingDetails
              history={history}
              address={address}
              pdfFile={pdfFile}
              key={index}
              city={city}
            />
          );
        })}
      </div>
    </div>
  );
};

const BuildingInfo = ({ data, width, history, setBuildingName, setBuildingClicked }) => {
  const [showAlert, setShowAlert] = useState(true);
  console.log("building data: ", data);
  return (
    <div
      // className="box-border grid w-full min-[1200px]:mx-auto min-[1200px]:w-fit min-[1200px]:grid-cols-building-2 min-[1200px]:gap-3 2xl:grid-cols-building-3 min-[1860px]:grid-cols-building-4 min-[2200px]:grid-cols-building-5"
      className=" w-full flex-col items-center justify-center overflow-x-hidden pb-10 sm:flex 2lg:w-[230px]"
    >
      {/* {showAlert && (
        <Alert
          setShowAlert={setShowAlert}
          className="mb-3 min-[1200px]:col-span-2 min-[1200px]:mb-0 2xl:col-span-3 min-[1860px]:col-span-4 min-[2200px]:col-span-5"
          message="The interactive maps on myApplied have been replaced with PDF maps of each building. We understand that this may make navigating our campus buildings a little more challenging, but we appreciate your patience as we actively seek an alternative interactive solution."
          // optionalMessage="Thank you for your patience during this transition."
        />
      )} */}
      {data.buildings.map((building) => (
        <BuildingCard
          history={history}
          building={building}
          key={building.name}
          width={width}
          city={data.city}
          setBuildingName={setBuildingName}
        />
      ))}
    </div>
  );
};

export default BuildingInfo;
